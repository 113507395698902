<template>
  <div>
    <div v-if="!isEdit" class="text-truncate" :class="{ 'mb-3': !lastItem }">
      {{ email }}
    </div>

    <div v-else>
      <TextField
        ref="email"
        isEmail
        isRequired
        showDeleteBtn
        label="이메일 *"
        :value.sync="_value"
        :showAddBtn="firstItem"
        :class="{ 'mb-2': !lastItem }"
        @click:add="$emit('click:add')"
        @click:delete="$emit('click:delete')"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { isBlank, isEmail } from "@/commons/utils/validation";
import TextField from "@/contact/views/components/list/common/TextField.vue";

export default {
  components: { TextField },
  props: {
    email: {
      type: String,
      default: ""
    },
    firstItem: {
      type: Boolean,
      default: false
    },
    lastItem: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters("customerCardDetail", ["isEdit"]),
    _value: {
      get() {
        return this.email;
      },
      set(email) {
        this.$emit("update:value", email);
      }
    }
  },
  methods: {
    focus() {
      this.$refs.email?.$refs?.textfield?.focus();
    },
    valid() {
      if (isBlank(this.email)) {
        this.focus();
        throw new Error("이메일 주소를 입력해 주세요.");
      }

      if (!isEmail(this.email)) {
        this.focus();
        throw new Error("이메일 주소가 올바르지 않습니다.");
      }
    }
  }
};
</script>
